
import React, {useEffect} from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";
import IndexPage from "./pages/IndexPage";
import AuthPage from "./pages/AuthPage";
import ArPage from "./pages/ArPage";
import Login from "./pages/Login"

// Each logical "route" has two components, one for
// the sidebar and one for the main area. We want to
// render both of them in different places when the
// path matches the current URL.

// We are going to use this route config in 2
// spots: once for the sidebar and once in the main
// content section. All routes are in the same
// order they would appear in a <Switch>.
const routes = [
  {
    path: "/",
    exact: true,
    sidebar: () => <div>home!</div>,
    main: () => <Login />
  },
  {
    path: "/bubblegum",
    sidebar: () => <div>bubblegum!</div>,
    main: () => <h2>Bubblegum</h2>
  },
  {
    path: "/shoelaces",
    sidebar: () => <div>shoelaces!</div>,
    main: () => <h2>Shoelaces</h2>
  }
];

export default function App() {

  useEffect(() => {
    // const eElement = document.getElementsByTagName("html");

    // const script = document.createElement('script');
    // script.src = "https://aframe.io/releases/1.0.4/aframe.min.js";
    // // script.async = true;
    // document.head.appendChild(script);

    // const script2 = document.createElement('script');
    // script2.src = "https://raw.githack.com/AR-js-org/AR.js/master/aframe/build/aframe-ar.js";
    // // script.async = true;
    // document.head.appendChild(script2);

    // document.body.appendChild(
    //     <a-scene embedded arjs>
    //         <a-marker preset="hiro" id="container">
    //             <a-entity rotation="-90 0 0">
    //                 <a-entity>
    //                     <a-box
    //                         position="0 0 0"
    //                         scale="0.25 2 0.25"
    //                         color="yellow"
    //                     ></a-box>
    //                     <a-text
    //                         position="0 -1.1 0"
    //                         align="center"
    //                         scale="0.3 0.3 0.3"
    //                         value="Hello, World!"
    //                     ></a-text>
    //                 </a-entity>
    //             </a-entity>
    //         </a-marker>
    //     </a-scene>
    // );

//         const list = document.getElementsByTagName("html");
// // list.insertBefore(newNode, list.children[0]);
// //         eElement.insertBefore(script, eElement.firstChild);
// //         eElement.insertBefore(script2, eElement.firstChild);
//         document.getElementsByTagName("html").insertBefore(script, list.firstChild);
//         document.getElementsByTagName("html").insertBefore(script2, list.firstChild);
    return () => {
        // document.head.removeChild(script);
        // document.head.removeChild(script2);
    }
}, []);

  return (
    <Router>
      <Switch>
          <Route exact path="/">
            <Login />
          </Route>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/app">
            {/* <AuthPage /> */}
            <ArPage />
          </Route>
        </Switch>
      {/* <div style={{ display: "flex" }}>
        <div
          style={{
            padding: "10px",
            width: "40%",
            background: "#f0f0f0"
          }}
        >
          <ul style={{ listStyleType: "none", padding: 0 }}>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/bubblegum">Bubblegum</Link>
            </li>
            <li>
              <Link to="/shoelaces">Shoelaces</Link>
            </li>
          </ul>

          <Switch>
            {routes.map((route, index) => (
              // You can render a <Route> in as many places
              // as you want in your app. It will render along
              // with any other <Route>s that also match the URL.
              // So, a sidebar or breadcrumbs or anything else
              // that requires you to render multiple things
              // in multiple places at the same URL is nothing
              // more than multiple <Route>s.
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.sidebar />}
              />
            ))}
          </Switch>
        </div>

        <div style={{ flex: 1, padding: "10px" }}>
          <Switch>
            {routes.map((route, index) => (
              // Render more <Route>s with the same paths as
              // above, but different components this time.
              <Route
                key={index}
                path={route.path}
                exact={route.exact}
                children={<route.main />}
              />
            ))}
          </Switch>
        </div>
      </div> */}
    </Router>
  );
}

