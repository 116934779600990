/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getReadTracking = /* GraphQL */ `
  query GetReadTracking($id: ID!) {
    getReadTracking(id: $id) {
      id
      readID
      timeCreated
      userID
      publisherID
      documentID
      timeFinished
      interaction
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReadTrackings = /* GraphQL */ `
  query ListReadTrackings(
    $filter: ModelReadTrackingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReadTrackings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        readID
        timeCreated
        userID
        publisherID
        documentID
        timeFinished
        interaction
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReadTrackings = /* GraphQL */ `
  query SyncReadTrackings(
    $filter: ModelReadTrackingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReadTrackings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        readID
        timeCreated
        userID
        publisherID
        documentID
        timeFinished
        interaction
        description
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRemote = /* GraphQL */ `
  query GetRemote($id: ID!) {
    getRemote(id: $id) {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listRemotes = /* GraphQL */ `
  query ListRemotes(
    $filter: ModelRemoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRemotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncRemotes = /* GraphQL */ `
  query SyncRemotes(
    $filter: ModelRemoteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRemotes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocumentInvitation = /* GraphQL */ `
  query GetDocumentInvitation($id: ID!) {
    getDocumentInvitation(id: $id) {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      document {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocumentInvitations = /* GraphQL */ `
  query ListDocumentInvitations(
    $filter: ModelDocumentInvitationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        adminUserID
        userEmail
        expirationDate
        accepted
        role
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        document {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocumentInvitations = /* GraphQL */ `
  query SyncDocumentInvitations(
    $filter: ModelDocumentInvitationFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocumentInvitations(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        adminUserID
        userEmail
        expirationDate
        accepted
        role
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        document {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      publisherID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        publisherID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncFavorites = /* GraphQL */ `
  query SyncFavorites(
    $filter: ModelFavoriteFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncFavorites(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        publisherID
        documentID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getRead = /* GraphQL */ `
  query GetRead($id: ID!) {
    getRead(id: $id) {
      id
      timeCreated
      userID
      publisherID
      documentID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      ReadTrackings {
        items {
          id
          readID
          timeCreated
          userID
          publisherID
          documentID
          timeFinished
          interaction
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      device
      deviceOS
      position
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listReads = /* GraphQL */ `
  query ListReads(
    $filter: ModelReadFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReads(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        ReadTrackings {
          nextToken
          startedAt
        }
        device
        deviceOS
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncReads = /* GraphQL */ `
  query SyncReads(
    $filter: ModelReadFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncReads(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        ReadTrackings {
          nextToken
          startedAt
        }
        device
        deviceOS
        position
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getLike = /* GraphQL */ `
  query GetLike($id: ID!) {
    getLike(id: $id) {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      documentID
      publisherID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listLikes = /* GraphQL */ `
  query ListLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLikes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        documentID
        publisherID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncLikes = /* GraphQL */ `
  query SyncLikes(
    $filter: ModelLikeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncLikes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        documentID
        publisherID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      content
      timeCreated
      userID
      documentID
      publisherID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        content
        timeCreated
        userID
        documentID
        publisherID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncComments = /* GraphQL */ `
  query SyncComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        content
        timeCreated
        userID
        documentID
        publisherID
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getEditor = /* GraphQL */ `
  query GetEditor($id: ID!) {
    getEditor(id: $id) {
      id
      userID
      email
      expiration
      publisherID
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listEditors = /* GraphQL */ `
  query ListEditors(
    $filter: ModelEditorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEditors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        email
        expiration
        publisherID
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncEditors = /* GraphQL */ `
  query SyncEditors(
    $filter: ModelEditorFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncEditors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        email
        expiration
        publisherID
        role
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getSubscriber = /* GraphQL */ `
  query GetSubscriber($id: ID!) {
    getSubscriber(id: $id) {
      id
      timeCreated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listSubscribers = /* GraphQL */ `
  query ListSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubscribers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncSubscribers = /* GraphQL */ `
  query SyncSubscribers(
    $filter: ModelSubscriberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSubscribers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPushDevices = /* GraphQL */ `
  query GetPushDevices($id: ID!) {
    getPushDevices(id: $id) {
      id
      deviceType
      deviceOS
      deviceModel
      token
      deviceName
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPushDevices = /* GraphQL */ `
  query ListPushDevices(
    $filter: ModelPushDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPushDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deviceType
        deviceOS
        deviceModel
        token
        deviceName
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPushDevices = /* GraphQL */ `
  query SyncPushDevices(
    $filter: ModelPushDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPushDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        deviceType
        deviceOS
        deviceModel
        token
        deviceName
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getChannelStaff = /* GraphQL */ `
  query GetChannelStaff($id: ID!) {
    getChannelStaff(id: $id) {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listChannelStaffs = /* GraphQL */ `
  query ListChannelStaffs(
    $filter: ModelChannelStaffFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChannelStaffs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        adminUserID
        userEmail
        expirationDate
        accepted
        role
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncChannelStaffs = /* GraphQL */ `
  query SyncChannelStaffs(
    $filter: ModelChannelStaffFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncChannelStaffs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        adminUserID
        userEmail
        expirationDate
        accepted
        role
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      name
      publisherID
      thumb
      background
      type
      isMaster
      allowMembershipRequest
      color
      description
      welcomeText
      members {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjects = /* GraphQL */ `
  query SyncProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getPublisher = /* GraphQL */ `
  query GetPublisher($id: ID!) {
    getPublisher(id: $id) {
      id
      name
      thumb
      background
      type
      subscribersCount
      userID
      isMaster
      certified
      allowMembershipRequest
      color
      description
      welcomeText
      Graphs {
        items {
          id
          data
          name
          type
          unit
          publisherID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      channel
      Editors {
        items {
          id
          userID
          email
          expiration
          publisherID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Assets {
        items {
          id
          name
          url
          type
          publisherID
          userID
          thumb
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MRs {
        items {
          id
          userID
          publisherID
          name
          thumb
          background
          type
          data
          timeCreated
          description
          requisites
          placeImage
          placeType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      email
      site
      topics
      phone
      social
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Members {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Managers {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Projects {
        items {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listPublishers = /* GraphQL */ `
  query ListPublishers(
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublishers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncPublishers = /* GraphQL */ `
  query SyncPublishers(
    $filter: ModelPublisherFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPublishers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getAsset = /* GraphQL */ `
  query GetAsset($id: ID!) {
    getAsset(id: $id) {
      id
      name
      url
      type
      publisherID
      userID
      thumb
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listAssets = /* GraphQL */ `
  query ListAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssets(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        url
        type
        publisherID
        userID
        thumb
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncAssets = /* GraphQL */ `
  query SyncAssets(
    $filter: ModelAssetFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAssets(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        name
        url
        type
        publisherID
        userID
        thumb
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMR = /* GraphQL */ `
  query GetMR($id: ID!) {
    getMR(id: $id) {
      id
      userID
      publisherID
      name
      thumb
      background
      type
      data
      timeCreated
      description
      requisites
      placeImage
      placeType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMRS = /* GraphQL */ `
  query ListMRS($filter: ModelMRFilterInput, $limit: Int, $nextToken: String) {
    listMRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        publisherID
        name
        thumb
        background
        type
        data
        timeCreated
        description
        requisites
        placeImage
        placeType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMRS = /* GraphQL */ `
  query SyncMRS(
    $filter: ModelMRFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMRS(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        publisherID
        name
        thumb
        background
        type
        data
        timeCreated
        description
        requisites
        placeImage
        placeType
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      userID
      publisherID
      projectID
      name
      description
      thumb
      background
      reference
      type
      price
      viewsCount
      favoritesCount
      bookmarksCount
      startAR
      topics
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      showComments
      state
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncDocuments = /* GraphQL */ `
  query SyncDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDocuments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getGraph = /* GraphQL */ `
  query GetGraph($id: ID!) {
    getGraph(id: $id) {
      id
      data
      name
      type
      unit
      publisherID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listGraphs = /* GraphQL */ `
  query ListGraphs(
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGraphs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        data
        name
        type
        unit
        publisherID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncGraphs = /* GraphQL */ `
  query SyncGraphs(
    $filter: ModelGraphFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGraphs(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        data
        name
        type
        unit
        publisherID
        userID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getMember = /* GraphQL */ `
  query GetMember($id: ID!) {
    getMember(id: $id) {
      id
      timeCreated
      userID
      publisherID
      documentID
      memberID
      memberEmail
      expirationDate
      accepted
      acceptedDate
      acceptedUserID
      role
      projects {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listMembers = /* GraphQL */ `
  query ListMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncMembers = /* GraphQL */ `
  query SyncMembers(
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const memberByUserEmail = /* GraphQL */ `
  query MemberByUserEmail(
    $memberEmail: String!
    $sortDirection: ModelSortDirection
    $filter: ModelMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    memberByUserEmail(
      memberEmail: $memberEmail
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      userID
      name
      email
      photo
      description
      PushDevices {
        items {
          id
          deviceType
          deviceOS
          deviceModel
          token
          deviceName
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites
      topics
      Publishers {
        items {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      social
      Memberships {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      EditorChannels {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Remote {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemoteId
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      nextToken
      startedAt
    }
  }
`;
export const syncUsers = /* GraphQL */ `
  query SyncUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncUsers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      nextToken
      startedAt
    }
  }
`;
export const userByEmail = /* GraphQL */ `
  query UserByEmail(
    $email: String!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByEmail(
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      nextToken
      startedAt
    }
  }
`;
export const getProjectMembers = /* GraphQL */ `
  query GetProjectMembers($id: ID!) {
    getProjectMembers(id: $id) {
      id
      projectID
      memberID
      project {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      member {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const listProjectMembers = /* GraphQL */ `
  query ListProjectMembers(
    $filter: ModelProjectMembersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectMembers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        projectID
        memberID
        project {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        member {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
export const syncProjectMembers = /* GraphQL */ `
  query SyncProjectMembers(
    $filter: ModelProjectMembersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncProjectMembers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        projectID
        memberID
        project {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        member {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      nextToken
      startedAt
    }
  }
`;
