/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onRemoteByID = /* GraphQL */ `
  subscription OnRemoteByID($id: ID!) {
    onRemoteByID(id: $id) {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onRemoteByUserID = /* GraphQL */ `
  subscription OnRemoteByUserID($userID: ID!) {
    onRemoteByUserID(userID: $userID) {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUserByID = /* GraphQL */ `
  subscription OnUserByID($id: ID!) {
    onUserByID(id: $id) {
      id
      userID
      name
      email
      photo
      description
      PushDevices {
        items {
          id
          deviceType
          deviceOS
          deviceModel
          token
          deviceName
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites
      topics
      Publishers {
        items {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      social
      Memberships {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      EditorChannels {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Remote {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemoteId
    }
  }
`;
export const onCreateReadTracking = /* GraphQL */ `
  subscription OnCreateReadTracking {
    onCreateReadTracking {
      id
      readID
      timeCreated
      userID
      publisherID
      documentID
      timeFinished
      interaction
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateReadTracking = /* GraphQL */ `
  subscription OnUpdateReadTracking {
    onUpdateReadTracking {
      id
      readID
      timeCreated
      userID
      publisherID
      documentID
      timeFinished
      interaction
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteReadTracking = /* GraphQL */ `
  subscription OnDeleteReadTracking {
    onDeleteReadTracking {
      id
      readID
      timeCreated
      userID
      publisherID
      documentID
      timeFinished
      interaction
      description
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRemote = /* GraphQL */ `
  subscription OnCreateRemote {
    onCreateRemote {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRemote = /* GraphQL */ `
  subscription OnUpdateRemote {
    onUpdateRemote {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRemote = /* GraphQL */ `
  subscription OnDeleteRemote {
    onDeleteRemote {
      id
      timeCreated
      userID
      description
      content
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDocumentInvitation = /* GraphQL */ `
  subscription OnCreateDocumentInvitation {
    onCreateDocumentInvitation {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      document {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDocumentInvitation = /* GraphQL */ `
  subscription OnUpdateDocumentInvitation {
    onUpdateDocumentInvitation {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      document {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDocumentInvitation = /* GraphQL */ `
  subscription OnDeleteDocumentInvitation {
    onDeleteDocumentInvitation {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      document {
        id
        userID
        publisherID
        projectID
        name
        description
        thumb
        background
        reference
        type
        price
        viewsCount
        favoritesCount
        bookmarksCount
        startAR
        topics
        Comments {
          nextToken
          startedAt
        }
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        showComments
        state
        DocumentInvitations {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateFavorite = /* GraphQL */ `
  subscription OnCreateFavorite {
    onCreateFavorite {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      publisherID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateFavorite = /* GraphQL */ `
  subscription OnUpdateFavorite {
    onUpdateFavorite {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      publisherID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteFavorite = /* GraphQL */ `
  subscription OnDeleteFavorite {
    onDeleteFavorite {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      publisherID
      documentID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateRead = /* GraphQL */ `
  subscription OnCreateRead {
    onCreateRead {
      id
      timeCreated
      userID
      publisherID
      documentID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      ReadTrackings {
        items {
          id
          readID
          timeCreated
          userID
          publisherID
          documentID
          timeFinished
          interaction
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      device
      deviceOS
      position
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateRead = /* GraphQL */ `
  subscription OnUpdateRead {
    onUpdateRead {
      id
      timeCreated
      userID
      publisherID
      documentID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      ReadTrackings {
        items {
          id
          readID
          timeCreated
          userID
          publisherID
          documentID
          timeFinished
          interaction
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      device
      deviceOS
      position
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteRead = /* GraphQL */ `
  subscription OnDeleteRead {
    onDeleteRead {
      id
      timeCreated
      userID
      publisherID
      documentID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      ReadTrackings {
        items {
          id
          readID
          timeCreated
          userID
          publisherID
          documentID
          timeFinished
          interaction
          description
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      device
      deviceOS
      position
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateLike = /* GraphQL */ `
  subscription OnCreateLike {
    onCreateLike {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      documentID
      publisherID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateLike = /* GraphQL */ `
  subscription OnUpdateLike {
    onUpdateLike {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      documentID
      publisherID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteLike = /* GraphQL */ `
  subscription OnDeleteLike {
    onDeleteLike {
      id
      timeCreated
      userID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      documentID
      publisherID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      content
      timeCreated
      userID
      documentID
      publisherID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      content
      timeCreated
      userID
      documentID
      publisherID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      content
      timeCreated
      userID
      documentID
      publisherID
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateEditor = /* GraphQL */ `
  subscription OnCreateEditor {
    onCreateEditor {
      id
      userID
      email
      expiration
      publisherID
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateEditor = /* GraphQL */ `
  subscription OnUpdateEditor {
    onUpdateEditor {
      id
      userID
      email
      expiration
      publisherID
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteEditor = /* GraphQL */ `
  subscription OnDeleteEditor {
    onDeleteEditor {
      id
      userID
      email
      expiration
      publisherID
      role
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateSubscriber = /* GraphQL */ `
  subscription OnCreateSubscriber {
    onCreateSubscriber {
      id
      timeCreated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateSubscriber = /* GraphQL */ `
  subscription OnUpdateSubscriber {
    onUpdateSubscriber {
      id
      timeCreated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteSubscriber = /* GraphQL */ `
  subscription OnDeleteSubscriber {
    onDeleteSubscriber {
      id
      timeCreated
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePushDevices = /* GraphQL */ `
  subscription OnCreatePushDevices {
    onCreatePushDevices {
      id
      deviceType
      deviceOS
      deviceModel
      token
      deviceName
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePushDevices = /* GraphQL */ `
  subscription OnUpdatePushDevices {
    onUpdatePushDevices {
      id
      deviceType
      deviceOS
      deviceModel
      token
      deviceName
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePushDevices = /* GraphQL */ `
  subscription OnDeletePushDevices {
    onDeletePushDevices {
      id
      deviceType
      deviceOS
      deviceModel
      token
      deviceName
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateChannelStaff = /* GraphQL */ `
  subscription OnCreateChannelStaff {
    onCreateChannelStaff {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateChannelStaff = /* GraphQL */ `
  subscription OnUpdateChannelStaff {
    onUpdateChannelStaff {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteChannelStaff = /* GraphQL */ `
  subscription OnDeleteChannelStaff {
    onDeleteChannelStaff {
      id
      timeCreated
      userID
      publisherID
      documentID
      adminUserID
      userEmail
      expirationDate
      accepted
      role
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateProject = /* GraphQL */ `
  subscription OnCreateProject {
    onCreateProject {
      id
      name
      publisherID
      thumb
      background
      type
      isMaster
      allowMembershipRequest
      color
      description
      welcomeText
      members {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProject = /* GraphQL */ `
  subscription OnUpdateProject {
    onUpdateProject {
      id
      name
      publisherID
      thumb
      background
      type
      isMaster
      allowMembershipRequest
      color
      description
      welcomeText
      members {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProject = /* GraphQL */ `
  subscription OnDeleteProject {
    onDeleteProject {
      id
      name
      publisherID
      thumb
      background
      type
      isMaster
      allowMembershipRequest
      color
      description
      welcomeText
      members {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreatePublisher = /* GraphQL */ `
  subscription OnCreatePublisher {
    onCreatePublisher {
      id
      name
      thumb
      background
      type
      subscribersCount
      userID
      isMaster
      certified
      allowMembershipRequest
      color
      description
      welcomeText
      Graphs {
        items {
          id
          data
          name
          type
          unit
          publisherID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      channel
      Editors {
        items {
          id
          userID
          email
          expiration
          publisherID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Assets {
        items {
          id
          name
          url
          type
          publisherID
          userID
          thumb
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MRs {
        items {
          id
          userID
          publisherID
          name
          thumb
          background
          type
          data
          timeCreated
          description
          requisites
          placeImage
          placeType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      email
      site
      topics
      phone
      social
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Members {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Managers {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Projects {
        items {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdatePublisher = /* GraphQL */ `
  subscription OnUpdatePublisher {
    onUpdatePublisher {
      id
      name
      thumb
      background
      type
      subscribersCount
      userID
      isMaster
      certified
      allowMembershipRequest
      color
      description
      welcomeText
      Graphs {
        items {
          id
          data
          name
          type
          unit
          publisherID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      channel
      Editors {
        items {
          id
          userID
          email
          expiration
          publisherID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Assets {
        items {
          id
          name
          url
          type
          publisherID
          userID
          thumb
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MRs {
        items {
          id
          userID
          publisherID
          name
          thumb
          background
          type
          data
          timeCreated
          description
          requisites
          placeImage
          placeType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      email
      site
      topics
      phone
      social
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Members {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Managers {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Projects {
        items {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeletePublisher = /* GraphQL */ `
  subscription OnDeletePublisher {
    onDeletePublisher {
      id
      name
      thumb
      background
      type
      subscribersCount
      userID
      isMaster
      certified
      allowMembershipRequest
      color
      description
      welcomeText
      Graphs {
        items {
          id
          data
          name
          type
          unit
          publisherID
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      channel
      Editors {
        items {
          id
          userID
          email
          expiration
          publisherID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Assets {
        items {
          id
          name
          url
          type
          publisherID
          userID
          thumb
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Documents {
        items {
          id
          userID
          publisherID
          projectID
          name
          description
          thumb
          background
          reference
          type
          price
          viewsCount
          favoritesCount
          bookmarksCount
          startAR
          topics
          showComments
          state
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      MRs {
        items {
          id
          userID
          publisherID
          name
          thumb
          background
          type
          data
          timeCreated
          description
          requisites
          placeImage
          placeType
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      email
      site
      topics
      phone
      social
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Members {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Managers {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Projects {
        items {
          id
          name
          publisherID
          thumb
          background
          type
          isMaster
          allowMembershipRequest
          color
          description
          welcomeText
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateAsset = /* GraphQL */ `
  subscription OnCreateAsset {
    onCreateAsset {
      id
      name
      url
      type
      publisherID
      userID
      thumb
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateAsset = /* GraphQL */ `
  subscription OnUpdateAsset {
    onUpdateAsset {
      id
      name
      url
      type
      publisherID
      userID
      thumb
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteAsset = /* GraphQL */ `
  subscription OnDeleteAsset {
    onDeleteAsset {
      id
      name
      url
      type
      publisherID
      userID
      thumb
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMR = /* GraphQL */ `
  subscription OnCreateMR {
    onCreateMR {
      id
      userID
      publisherID
      name
      thumb
      background
      type
      data
      timeCreated
      description
      requisites
      placeImage
      placeType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMR = /* GraphQL */ `
  subscription OnUpdateMR {
    onUpdateMR {
      id
      userID
      publisherID
      name
      thumb
      background
      type
      data
      timeCreated
      description
      requisites
      placeImage
      placeType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMR = /* GraphQL */ `
  subscription OnDeleteMR {
    onDeleteMR {
      id
      userID
      publisherID
      name
      thumb
      background
      type
      data
      timeCreated
      description
      requisites
      placeImage
      placeType
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateDocument = /* GraphQL */ `
  subscription OnCreateDocument {
    onCreateDocument {
      id
      userID
      publisherID
      projectID
      name
      description
      thumb
      background
      reference
      type
      price
      viewsCount
      favoritesCount
      bookmarksCount
      startAR
      topics
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      showComments
      state
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateDocument = /* GraphQL */ `
  subscription OnUpdateDocument {
    onUpdateDocument {
      id
      userID
      publisherID
      projectID
      name
      description
      thumb
      background
      reference
      type
      price
      viewsCount
      favoritesCount
      bookmarksCount
      startAR
      topics
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      showComments
      state
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteDocument = /* GraphQL */ `
  subscription OnDeleteDocument {
    onDeleteDocument {
      id
      userID
      publisherID
      projectID
      name
      description
      thumb
      background
      reference
      type
      price
      viewsCount
      favoritesCount
      bookmarksCount
      startAR
      topics
      Comments {
        items {
          id
          content
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Likes {
        items {
          id
          timeCreated
          userID
          documentID
          publisherID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Reads {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          device
          deviceOS
          position
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Favorites {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      showComments
      state
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateGraph = /* GraphQL */ `
  subscription OnCreateGraph {
    onCreateGraph {
      id
      data
      name
      type
      unit
      publisherID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateGraph = /* GraphQL */ `
  subscription OnUpdateGraph {
    onUpdateGraph {
      id
      data
      name
      type
      unit
      publisherID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteGraph = /* GraphQL */ `
  subscription OnDeleteGraph {
    onDeleteGraph {
      id
      data
      name
      type
      unit
      publisherID
      userID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateMember = /* GraphQL */ `
  subscription OnCreateMember {
    onCreateMember {
      id
      timeCreated
      userID
      publisherID
      documentID
      memberID
      memberEmail
      expirationDate
      accepted
      acceptedDate
      acceptedUserID
      role
      projects {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateMember = /* GraphQL */ `
  subscription OnUpdateMember {
    onUpdateMember {
      id
      timeCreated
      userID
      publisherID
      documentID
      memberID
      memberEmail
      expirationDate
      accepted
      acceptedDate
      acceptedUserID
      role
      projects {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteMember = /* GraphQL */ `
  subscription OnDeleteMember {
    onDeleteMember {
      id
      timeCreated
      userID
      publisherID
      documentID
      memberID
      memberEmail
      expirationDate
      accepted
      acceptedDate
      acceptedUserID
      role
      projects {
        items {
          id
          projectID
          memberID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      publisher {
        id
        name
        thumb
        background
        type
        subscribersCount
        userID
        isMaster
        certified
        allowMembershipRequest
        color
        description
        welcomeText
        Graphs {
          nextToken
          startedAt
        }
        channel
        Editors {
          nextToken
          startedAt
        }
        Assets {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        MRs {
          nextToken
          startedAt
        }
        email
        site
        topics
        phone
        social
        Likes {
          nextToken
          startedAt
        }
        Reads {
          nextToken
          startedAt
        }
        Favorites {
          nextToken
          startedAt
        }
        Comments {
          nextToken
          startedAt
        }
        Members {
          nextToken
          startedAt
        }
        Managers {
          nextToken
          startedAt
        }
        Projects {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      user {
        id
        userID
        name
        email
        photo
        description
        PushDevices {
          nextToken
          startedAt
        }
        favorites
        topics
        Publishers {
          nextToken
          startedAt
        }
        social
        Memberships {
          nextToken
          startedAt
        }
        EditorChannels {
          nextToken
          startedAt
        }
        DocumentInvitations {
          nextToken
          startedAt
        }
        Remote {
          id
          timeCreated
          userID
          description
          content
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        userRemoteId
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      userID
      name
      email
      photo
      description
      PushDevices {
        items {
          id
          deviceType
          deviceOS
          deviceModel
          token
          deviceName
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites
      topics
      Publishers {
        items {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      social
      Memberships {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      EditorChannels {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Remote {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemoteId
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      userID
      name
      email
      photo
      description
      PushDevices {
        items {
          id
          deviceType
          deviceOS
          deviceModel
          token
          deviceName
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites
      topics
      Publishers {
        items {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      social
      Memberships {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      EditorChannels {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Remote {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemoteId
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      userID
      name
      email
      photo
      description
      PushDevices {
        items {
          id
          deviceType
          deviceOS
          deviceModel
          token
          deviceName
          userID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      favorites
      topics
      Publishers {
        items {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      social
      Memberships {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          memberID
          memberEmail
          expirationDate
          accepted
          acceptedDate
          acceptedUserID
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      EditorChannels {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      DocumentInvitations {
        items {
          id
          timeCreated
          userID
          publisherID
          documentID
          adminUserID
          userEmail
          expirationDate
          accepted
          role
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        nextToken
        startedAt
      }
      Remote {
        id
        timeCreated
        userID
        description
        content
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      userRemoteId
    }
  }
`;
export const onCreateProjectMembers = /* GraphQL */ `
  subscription OnCreateProjectMembers {
    onCreateProjectMembers {
      id
      projectID
      memberID
      project {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      member {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onUpdateProjectMembers = /* GraphQL */ `
  subscription OnUpdateProjectMembers {
    onUpdateProjectMembers {
      id
      projectID
      memberID
      project {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      member {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
export const onDeleteProjectMembers = /* GraphQL */ `
  subscription OnDeleteProjectMembers {
    onDeleteProjectMembers {
      id
      projectID
      memberID
      project {
        id
        name
        publisherID
        thumb
        background
        type
        isMaster
        allowMembershipRequest
        color
        description
        welcomeText
        members {
          nextToken
          startedAt
        }
        Documents {
          nextToken
          startedAt
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      member {
        id
        timeCreated
        userID
        publisherID
        documentID
        memberID
        memberEmail
        expirationDate
        accepted
        acceptedDate
        acceptedUserID
        role
        projects {
          nextToken
          startedAt
        }
        publisher {
          id
          name
          thumb
          background
          type
          subscribersCount
          userID
          isMaster
          certified
          allowMembershipRequest
          color
          description
          welcomeText
          channel
          email
          site
          topics
          phone
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
        }
        user {
          id
          userID
          name
          email
          photo
          description
          favorites
          topics
          social
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          userRemoteId
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
    }
  }
`;
