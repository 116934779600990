import ReactDOM from "react-dom";
import React, {
  useContext,
  createContext,
  useState,
  useEffect,
  useRef,
} from "react";
import { ARCanvas, ARMarker } from "@artcom/react-three-arjs";
import { Canvas, useFrame, useThree, forceResize } from "@react-three/fiber";
import * as THREE from "three";
import {
  CreateBar,
  CreatePie,
  angle,
  AdaptivePixelRatio,
  CreateContent,
} from "./objects";
import assets from "../../assets";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Auth, Cache, API, graphqlOperation, graphql } from "aws-amplify";
import { getUser } from "../../graphql/queries";
import { onRemoteByID } from "../../graphql/subscriptions";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ArPage() {
  const handle = useFullScreenHandle();
  const [state, setState] = useState({});
  const [user, setUser] = useState();
  const [unlogged, setUnlogged] = useState(false);
  const [remote, setRemote] = useState();
  const [remoteData, setRemoteData] = useState();
  const [remoteID, setRemoteID] = useState();

  function showToast(texto) {
    toast(texto || "Realidade aumentada atualizada!", {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "light",
        });
  }

  useEffect(() => {
    // main();
    Auth.currentSession()
      .then((userSession) => {
        console.log("Got user currentSession:");
        setUser(userSession?.idToken?.payload);
        // queryRemote(userSession?.idToken?.payload)
      })
      .catch((err) => {
        setUnlogged(true);
        console.log(err);
      });

    return () => {};
  }, []);

  useEffect(() => {
    if (!remoteID) return;

    console.log('REMOTEID', remoteID);
    
    const subscription = API.graphql(graphqlOperation(onRemoteByID, {id: remoteID})).subscribe(
        {
            next: (evt) => {
                const {
                    "value":{
                       "data":{
                          "onRemoteByID": remoteByID
                       }
                    }
                } = evt;

                // console.log(JSON.stringify(evt));
                const contentParse = JSON.parse(remoteByID.content);
                console.log('SUBS:', JSON.stringify(contentParse));
                setRemote(contentParse);
                showToast();
            },
        }
    )
  
    return () => {
        return subscription;
    }
  }, [remoteID])
  

  useEffect(() => {
    if (!user) return;


        // setRemote({
        //     data: [
        //         {
        //            "color":"red",
        //            "label":"MG",
        //            "value":80
        //         },
        //         {
        //            "color":"orange",
        //            "label":"SP",
        //            "value":40
        //         },
        //         {
        //            "color":"blue",
        //            "label":"RJ",
        //            "value":28
        //         }
        //      ],
        //      type: "pie"
        // })

    queryRemote(user);

    

    return () => {};
  }, [user]);



  function queryRemote(userRemote) {

    API.graphql(graphqlOperation(getUser, { id: userRemote.profile }))
      .then((userGet) => {
        const {
          data: {
            getUser: userA,
          },
        } = userGet;

        const { Remote: remotes } = userA;

        const contentParse = JSON.parse(remotes.content);
        setRemoteID(userA?.userRemoteId);
        console.log('CONTENT:',JSON.stringify(contentParse));
        // console.log('USER', JSON.stringify(userA));
        setRemote(contentParse);
        showToast();
      })
      .catch((err) => {
        console.log(err);
      });

  }

  function handleLogout() {
    Auth.signOut()
      .then((data) => {
        setUser();
        setUnlogged(true);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  return (
    <>
    <ToastContainer />
      {unlogged && <Redirect to="/login" />}
      <img
        className="absolute top-5 right-5 h-24 w-auto p-8"
        src={assets.logo}
      ></img>
      {user && (
        <div>
          {/* <div><b>Your tokenId:</b></div><div>{state.tokenId}</div>
                <div><b>Your refreshToken:</b></div><div>{state.refreshToken}</div> */}
          <button
            className="z-10 absolute top-5 left-5 btn-toggle flex px-5 py-3 text-white justify-center bg-white text-black hover:bg-gray-200 rounded-full shadow w-auto text-center"
            onClick={handleLogout}
          >
            Sair
          </button>
        </div>
      )}
      {/* <button className="float-left" onClick={handle.enter}>
                Enter fullscreen
            </button> */}
      <FullScreen handle={handle}>
        {/* <Canvas
            onCreated={({ gl }) => {
                gl.setSize(window.innerWidth, window.innerHeight);
            }}
        >
            <mesh scale={[1, 1, 1]} ref={canvas}>
                <ambientLight />
                <pointLight position={[10, 10, 10]} />
                <Box position={[-1.2, 0, 0]} />
                <Box position={[1.2, 0, 0]} />
                <CreatePie position={[0, 0, 0]} rotation={[angle(0), 0, 0]} scale={[0.5, 0.5, 0.5]}/>
            </mesh>
        </Canvas> */}

        <ARCanvas
          // ref={canvas}
        //   gl={{
        //     antialias: false,
        //     powerPreference: "default",
        //     physicallyCorrectLights: true,
        //   }}
        //   onCameraStreamReady={() => console.log("Camera stream ready")}
        //   onCameraStreamError={() => console.error("Camera stream error")}
        //   onCreated={({ gl }) => {
        //     gl.setSize(window.innerWidth, window.innerHeight);
        //     // setSize(window.innerWidth, window.innerHeight)
        //   }}
        //   onPointerMissed={() => console.log("canvas.missed")}



          // style={{flex: 1}}
          // const onLayout = React.useCallback((e: LayoutChangeEvent) => {
          //     const { width, height, x, y } = e.nativeEvent.layout
          //     setSize({ width, height, top: y, left: x })
          //   }, [])
          // onLayout={({ width, height }) => {
          //     // gl.setSize(window.innerWidth, window.innerHeight)
          //     console.log('REsize')
          // }}
          // camera={{ position: [0, 0, 0] }}
          // dpr={window.devicePixelRatio}
          // onCreated={({ gl }) => {
          //     gl.setSize(window.innerWidth, window.innerHeight);
          // }}
          // children                                        // regular children
          // arEnabled                                       // if false, it will render children into <Canvas /> without AR context
          // tracking
          // embedded
          // patternRatio={0.5}                              // passed to arToolkit context ¹
          // detectionMode="mono_and_matrix"               // passed to arToolkit context ¹
          cameraParametersUrl="data/camera_para.dat"    // passed to arToolkit context ¹
          // matrixCodeType="3x3"                          // passed to arToolkit context ¹
          sourceType="webcam"                           // set camera source type, see ar.js code ²
          // orthographic={true}
        >
          <AdaptivePixelRatio>
            <ambientLight />
            <pointLight position={[5, 5, 0]} />
            <ARMarker
              params={{ smooth: true }}
              type={"pattern"}
              patternUrl={"data/huveAR.patt"}
              onMarkerFound={(valor) => {
                console.log("Marker Found", valor);
              }}
              onMarkerLost={(valor) => {
                console.log("Marker Lost", valor);
              }}
              // maxDetectionRate={60}
            >
                <CreateContent data={remote?.data} type={remote?.type} />
            </ARMarker>
          </AdaptivePixelRatio>
        </ARCanvas>
      </FullScreen>
    </>
  );
}
