
import { Download, Features, SectionWrapper, Footer, LoginWrapper } from '../../components';
import assets from '../../assets';
import styles from '../../styles/Global';

const IndexPage = () => {
  return (
    <>
      <LoginWrapper 
        title="Compartilhe sua realidade"
        description="Crie, compartilhe, apresente e impressione com realidades aumentadas."
        showBtn
        mockupImg={assets.homeHero}
        banner="banner"
        showLogo
      />
    </>
  );
}

export default IndexPage;
