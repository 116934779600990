import React from 'react';
import ReactDOM from 'react-dom';
import { createRoot } from "react-dom/client"


import './index.css';
import App from './App';

// ReactDOM.render(
//   <React.StrictMode>
//     <App />
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// function Box() {
//   return (
//     <mesh>
//       <boxBufferGeometry args={[1, 1, 1]} />
//       <meshStandardMaterial color={"hotpink"} />
//     </mesh>
//   )
// }

createRoot(document.getElementById("root")).render(
  <App />,
)