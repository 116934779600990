import homeHero from "./home_hero.png";
import homeCards from "./home_cards.png";
import feature from "./feature.png";
import expo from "./expo.png";
import expo02 from "./expo02.png";
import javascript from "./javascript.png";
import react from "./react.png";
import scene from "./scene.png";
import mockup from "./mockup.png";
import logo from "./HuveLogoFlat.svg"
import googlePlayLogo from "./google-play-logo.png";
import appleLogo from "./apple-logo.png";
import arBrain from "./Icons/ar_brain.png";
import arFloatingLand from "./Icons/ar_floating_land.png";
import arShare from "./Icons/ar_share.png";
import arHandReality from "./Icons/ar_hand_reality.png";
import arMobile from "./Icons/ar_mobile.png";
import arPortal from "./Icons/ar_portal.png";
import arRender from "./Icons/ar_reader.png";
import logoHuve from "./logo.svg"
import googleLogo from "./google-logo.png"



export default {
  homeHero,
  homeCards,
  feature,
  expo,
  expo02,
  javascript,
  react,
  scene,
  mockup,
  logo,
  googlePlayLogo,
  appleLogo,
  arBrain,
  arFloatingLand,
  arShare,
  arHandReality,
  arMobile,
  arPortal,
  arRender,
  logoHuve,
  googleLogo
};
