import ReactDOM from "react-dom"
import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import { ARCanvas, ARMarker } from "@artcom/react-three-arjs"
import { Canvas, useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import { meshPhongMaterial, RoundedBox, Text, Box, Center, MeshReflectorMaterial, Text3D, meshNormalMaterial, Circle, useAspect, OrthographicCamera } from '@react-three/drei'


export function AdaptivePixelRatio({ children, ...props }) {
    const gl = useThree((state) => state.gl)
    const current = useThree((state) => state.performance.current)
    const initialDpr = useThree((state) => state.viewport.initialDpr)
    const setDpr = useThree((state) => state.setDpr)
    const viewport = useThree((state) => state.viewport)
    const setSize = useThree(state => state.setSize)
    const camera = useThree(state => state.camera)
    // const video = document.getElementById('video');
    const video = document.getElementById('arjs-video');
    const size = useThree(state => state.size)
    const scale = useAspect(
        1024,                     // Pixel-width
        512,                      // Pixel-height
        1                         // Optional scaling factor
    )

    useEffect(() => {
      console.log('VIDEO', video)

    //   video.addEventListener('loadedmetadata', function() {
    //     gl.translate(window.innerWidth, 0); 
    //     gl.scale(-1, 1);
    //  });

    
      return () => {
      }
    }, [video])
    

    //   useEffect(() => {
    //     // if (!(camera instanceof THREE.Camera)) {
    //     //     console.log('SSSSSSS')
    //         // if (OrthographicCamera(camera)) {
    //         //   camera.left = size.width / -2
    //         //   camera.right = size.width / 2
    //         //   camera.top = size.height / 2
    //         //   camera.bottom = size.height / -2
    //         // } else {
    //         //   camera.aspect = size.width / size.height
    //         // }

    //         console.log(window.innerWidth, window.innerHeight);  

    //         camera.aspect = window.innerWidth /  window.innerHeight;
    //         console.log('SSSSSSS', window.innerWidth,  window.innerHeight);
    //         camera.updateProjectionMatrix()
    //         // https://github.com/pmndrs/react-three-fiber/issues/178
    //         // Update matrix world since the renderer is a frame late
    //         camera.updateMatrixWorld()
    //     //   }
    //       // Update renderer
    //       gl.setPixelRatio(viewport.dpr)
    //       gl.setSize(window.innerWidth,  window.innerHeight)
    //     return () => {
    //     }
    //   }, [camera])


    //   rootState.subscribe(
    //     () => {
    //       const { camera, size, viewport, internal } = rootState.getState()
    //       // https://github.com/pmndrs/react-three-fiber/issues/92
    //       // Do not mess with the camera if it belongs to the user
    //       if (!(internal.lastProps.camera instanceof THREE.Camera)) {
    //         if (isOrthographicCamera(camera)) {
    //           camera.left = size.width / -2
    //           camera.right = size.width / 2
    //           camera.top = size.height / 2
    //           camera.bottom = size.height / -2
    //         } else {
    //           camera.aspect = size.width / size.height
    //         }
    //         camera.updateProjectionMatrix()
    //         // https://github.com/pmndrs/react-three-fiber/issues/178
    //         // Update matrix world since the renderer is a frame late
    //         camera.updateMatrixWorld()
    //       }
    //       // Update renderer
    //       gl.setPixelRatio(viewport.dpr)
    //       gl.setSize(size.width, size.height)
    //     },
    //     (state) => [state.viewport.dpr, state.size],
    //     shallow,
    //   )

    // Restore initial pixelratio on unmount
    useEffect(() => {
        const domElement = gl.domElement
        return () => {
            setDpr(initialDpr)
            domElement.style.imageRendering = 'auto'
        }
    }, [])

    // Set adaptive pixelratio
    useEffect(() => {
        setDpr(current * initialDpr)
        gl.domElement.style.imageRendering = current === 1 ? 'auto' : 'pixelated'
    }, [current])

    // useEffect(() => {
    //     setSize(window.innerWidth, window.innerHeight);
    //     console.log(window.innerWidth, window.innerHeight);    
    //   return () => {
    //   }
    // }, [setSize])


    useEffect(() => {
        console.log('VIEWPORT', JSON.stringify(viewport), window.innerWidth, window.innerHeight);
        gl.setPixelRatio(viewport.aspect)

        // gl.setSize(window.innerWidth,  window.innerHeight) 
        viewport.width = window.innerWidth;
        viewport.height = window.innerHeight;
        camera.aspect = viewport.aspect;
        camera.width = window.innerWidth;
        camera.height = window.innerHeight;
        //     camera.left = 0
        //     // camera.right = window.innerWidth
        //     camera.top = 0
        //     // camera.bottom = window.innerHeight
        camera.updateProjectionMatrix()
        camera.updateMatrixWorld()

        // setSize(window.innerWidth, window.innerHeight);
        return () => {
        }
    }, [viewport.aspect])



    //   React.useEffect(() => {
    //     function handleResize() {
    //         gl.setSize(window.innerWidth, window.innerHeight);
    //         console.log('Window Resize')
    // }

    //     window.addEventListener('resize', handleResize)
    //   });



    return <>{children}</>
}

export function angle(degrees) {
    var pi = Math.PI;
    return degrees * (pi / 180);
}

function BoxHover({ color, ...props }) {
    // This reference gives us direct access to the THREE.Mesh object
    const ref = useRef()
    // Hold state for hovered and clicked events
    const [hovered, hover] = useState(false)
    const [clicked, click] = useState(false)
    // Subscribe this component to the render-loop, rotate the mesh every frame
    // useFrame((state, delta) => (ref.current.rotation.z += 0.01))
    // Return the view, these are regular Threejs elements expressed in JSX
    return (
        <mesh
            {...props}
            ref={ref}
            // scale={clicked ? 1.5 : 1}
            onClick={(event) => click(!clicked)}
            onPointerOver={(event) => hover(true)}
            onPointerOut={(event) => hover(false)}>
            <boxGeometry args={[1, 1, 1]} />
            <meshStandardMaterial color={hovered ? 'hotpink' : color || 'orange'} />
        </mesh>
    )
}


function Test() {
    const [o1] = useState(
        () => new THREE.Mesh(new THREE.TextGeometry('Hello three.js!', {
            size: 80,
            height: 5,
            curveSegments: 12,
            bevelEnabled: true,
            bevelThickness: 10,
            bevelSize: 8,
            bevelOffset: 0,
            bevelSegments: 5
        })
            , new THREE.MeshBasicMaterial({ color: 'hotpink' })),
    )
    const [o2] = useState(
        () => new THREE.Mesh(new THREE.BoxGeometry(), new THREE.MeshBasicMaterial({ color: 'aquamarine' })),
    )
    // const [which, toggle] = useReducer((state) => !state, true)
    // useEffect(() => {
    //   const interval = setInterval(toggle, 1000)
    //   return () => clearInterval(interval)
    // }, [])

    // useFrame((state) => {
    //     console.log(state.pointer.x)
    // })

    return <primitive object={o2} />
}




export function CreateBarNew({ ...props }) {
    return (<Center disableZ>

        <mesh rotation={[angle(-90), 0, 0]} position={[-1, 0, 0]}>
            <Center top>
                <RoundedBox args={[1, 3, 1]} radius={0.05} smoothness={4} rotation={[0, 0, 0]}>
                    {/* <meshPhongMaterial color="blue" wireframe/> */}
                    <meshPhongMaterial color="blue" />
                </RoundedBox>
            </Center>
            <Center right position={[0, 0.5, 0]}>
                <mesh>
                    <Text color="black" anchorX="center" anchorY="middle" rotation={[0, 0, 0]} scale={[2, 2, 2]} >
                        hello world Higor!
                    </Text>
                </mesh>
            </Center>
        </mesh>

        <mesh rotation={[angle(-90), 0, 0]} position={[1, 0, 0]}>
            <Center top>
                <RoundedBox args={[1, 5, 1]} radius={0.05} smoothness={4} rotation={[0, 0, 0]}>
                    {/* <meshPhongMaterial color="blue" wireframe/> */}
                    <meshPhongMaterial color="red" />
                </RoundedBox>
            </Center>
            <Center right position={[0, 0.5, 0]}>
                <mesh>
                    <Text color="black" anchorX="center" anchorY="middle" rotation={[0, 0, 0]} scale={[2, 2, 2]} >
                        hello world Higor!
                    </Text>
                </mesh>
            </Center>
        </mesh>

    </Center>
    )
}

export function CreateContent({data, type, ...props}) {
    // return (<CreateBarNew />)
    if (type == 'bar') {
        return (
            <CreateBar data={data} position={[0, 0, 0]} rotation={[angle(-90), 0, 0]} scale={[1, 1, 1]}/> 
        )
    } else if (type == 'pie') {
        return (  
            <CreatePie data={data} position={[0, 0, 0]} rotation={[angle(-90), 0, 0]} scale={[0.8, 0.8, 0.8]}/>
            )

    } else {
        return <mesh></mesh>
    }
}

export function CreatePie({data, ...props }) {

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color('white');
    const ref = useRef()
    // Hold state for hovered and clicked events
    // Subscribe this component to the render-loop, rotate the mesh every frame
    // useFrame((state, delta) => (ref.current.rotation.y += 0.01))

    const dataSource = data || [
        {
            value: Math.random() * 10,
            label: "Vinho",
            color: "blue"
        },
        {
            value: Math.random() * 10,
            label: "Azeite",
            color: "yellow"
        },
        {
            value: Math.random() * 10,
            label: "iPhone",
            color: "white"
        },
        {
            value: Math.random() * 10,
            label: "Milho",
            color: "grey"
        },
        {
            value: Math.random() * 10,
            label: "Feijão",
            color: "red"
        },
        {
            value: Math.random() * 10,
            label: "Soja",
            color: "orange"
        }
    ]


    function addLight(position) {
        const color = 0xFFFFFF;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(...position);
        scene.add(light);
        scene.add(light.target);
    }
    addLight([-3, 1, 1]);
    addLight([2, 1, .5]);

    function makeLabelCanvas(baseWidth, size, name) {
        const borderSize = 2;
        const ctx = document.createElement('canvas').getContext('2d');
        const font = `${size}px bold sans-serif`;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;

        const doubleBorderSize = borderSize * 2;
        const width = baseWidth + doubleBorderSize;
        const height = size + doubleBorderSize;
        ctx.canvas.width = width;
        ctx.canvas.height = height;

        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.fillRect(0, 0, width, height);

        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor, 1);
        ctx.fillStyle = 'white';
        ctx.fillText(name, 0, 0);

        return ctx.canvas;
    }

    function makePie(value, maxValor, totalValor, sumValue, labelWidth, size, name, color) {

        const radius = 4;
        const segments = 64;
        const thetaStartDegrees = Math.round((sumValue / totalValor) * 360); //0;
        const thetaLengthDegrees = Math.round((value / totalValor) * 360);//5;
        const thetaStart = angle(thetaStartDegrees); //0;
        const thetaLength = angle(thetaLengthDegrees);//5;
        const anglePosition = angle(thetaStartDegrees + (thetaLengthDegrees / 2));

        const bodyGeometry = new THREE.CircleGeometry(radius, segments, thetaStart, thetaLength)


        const canvas = makeLabelCanvas(labelWidth, size, `${name}`);
        const texture = new THREE.CanvasTexture(canvas);
        // because our canvas is likely not a power of 2
        // in both dimensions set the filtering appropriately.
        texture.minFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        const labelMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
        });
        const bodyMaterial = new THREE.MeshPhongMaterial({
            color,
            flatShading: true,
        });

        // const bodyMaterial = new THREE.MeshBasicMaterial( { color } );

        const root = new THREE.Object3D();
        // root.position.x = 0;

        const body = new THREE.Mesh(bodyGeometry, bodyMaterial);
        root.add(body);
        // body.position.y = bodyHeight / 2;

        // if units are meters then 0.01 here makes size
        // of the label into centimeters.
        const labelBaseScale = 0.01;
        const label = new THREE.Sprite(labelMaterial);
        root.add(label);
        // label.position.y = head.position.y + headRadius + size * labelBaseScale;

        label.position.x = radius * 1.3 * Math.cos(anglePosition);
        label.position.y = radius * 1.3 * Math.sin(anglePosition);
        // label.position.y = -size * labelBaseScale;
        // label.position.z = bodyDepth / 2;

        label.scale.x = canvas.width * labelBaseScale;
        label.scale.y = canvas.height * labelBaseScale;

        // Subscribe this component to the render-loop, rotate the mesh every frame
        // useFrame((state, delta) => (ref.current.rotation.z += 0.01))
        // Return the view, these are regular Threejs elements expressed in JSX

        // onPointerOver={(event) => hover(true)}
        // onPointerOut={(event) => hover(false)}

        scene.add(root);
        return root;
    }

    var maxNumber = 0;
    var valorTotal = 0;
    // var posStart = -0.1*Math.round(data.length/2);
    var sumValue = 0;

    for (var i = 0; i < dataSource.length; i++) {
        const objeto = dataSource[i];
        valorTotal += objeto.value;
        if (maxNumber < objeto.value) {
            maxNumber = objeto.value
        };
    }



    // for (var i = 0; i < data.length; i++) {
    //     const objeto = data[i];
    //     // console.log(objeto);
    //     objects.push((<CreatePie label={objeto.label} positionX={0} positionY={.05} positionZ={0} valor={objeto.value} maxValor={maxNumber} totalValor={valorTotal} sumValue={sumValue} color={`${objeto.color}_texture`}/>
    //     ))

    // }

    for (var i = 0; i < dataSource.length; i++) {
        const objeto = dataSource[i];
        makePie(objeto.value, maxNumber, valorTotal, sumValue, 150, 32, objeto.label, objeto.color, objeto.value);
        sumValue += objeto.value;
    }


    return <mesh><primitive ref={ref} object={scene} {...props} /></mesh>

}


export function CreateBar({ data, start, ...props }) {

    const scene = new THREE.Scene();
    // scene.background = new THREE.Color('white');
    const ref = useRef()
    // Hold state for hovered and clicked events
    // Subscribe this component to the render-loop, rotate the mesh every frame
    // useFrame((state, delta) => (ref.current.rotation.y += 0.01))

    // useFrame((state, delta) => {
    //     // const rrrr = ref.current.rotation.y += 0.01;
    //     const rrrr = angle(90);
    //     ref.current.rotation.y = rrrr;

    //     console.log('ROT', rrrr)
    // });

    const dataSource = data || [
        {
            value: Math.random() * 10,
            label: "Vinho",
            color: "blue"
        },
        {
            value: Math.random() * 10,
            label: "Azeite",
            color: "yellow"
        },
        {
            value: Math.random() * 10,
            label: "iPhone",
            color: "white"
        },
        {
            value: Math.random() * 10,
            label: "Milho",
            color: "grey"
        },
        {
            value: Math.random() * 10,
            label: "Feijão",
            color: "red"
        },
        {
            value: Math.random() * 10,
            label: "Soja",
            color: "blue"
        }
    ]


    function addLight(position) {
        const color = 0xFFFFFF;
        const intensity = 1;
        const light = new THREE.DirectionalLight(color, intensity);
        light.position.set(...position);
        scene.add(light);
        scene.add(light.target);
    }
    addLight([-3, 1, 1]);
    addLight([2, 1, .5]);

    function makeLabelCanvas(baseWidth, size, name) {
        const borderSize = 2;
        const ctx = document.createElement('canvas').getContext('2d');
        const font = `${size}px bold sans-serif`;
        ctx.font = font;
        // measure how long the name will be
        const textWidth = ctx.measureText(name).width;

        const doubleBorderSize = borderSize * 2;
        const width = baseWidth + doubleBorderSize;
        const height = size + doubleBorderSize;
        ctx.canvas.width = width;
        ctx.canvas.height = height;

        // need to set font again after resizing canvas
        ctx.font = font;
        ctx.textBaseline = 'middle';
        ctx.textAlign = 'center';

        ctx.fillStyle = 'rgba(0,0,0,1)';
        ctx.fillRect(0, 0, width, height);

        // scale to fit but don't stretch
        const scaleFactor = Math.min(1, baseWidth / textWidth);
        ctx.translate(width / 2, height / 2);
        ctx.scale(scaleFactor, 1);
        ctx.fillStyle = 'white';
        ctx.fillText(name, 0, 0);

        return ctx.canvas;
    }

    function makeBar(x, labelWidth, size, name, color, value, maxNumber) {
        const bodyHeight = value * (7/maxNumber);
        const bodyWidth = 1;
        const bodyDepth = 1;
        const bodyGeometry = new THREE.BoxGeometry(bodyWidth, bodyHeight, bodyDepth);

        const canvas = makeLabelCanvas(labelWidth, size, name);
        const texture = new THREE.CanvasTexture(canvas);
        // because our canvas is likely not a power of 2
        // in both dimensions set the filtering appropriately.
        texture.minFilter = THREE.LinearFilter;
        texture.wrapS = THREE.ClampToEdgeWrapping;
        texture.wrapT = THREE.ClampToEdgeWrapping;

        const labelMaterial = new THREE.SpriteMaterial({
            map: texture,
            transparent: true,
        });
        const bodyMaterial = new THREE.MeshPhongMaterial({
            color,
            flatShading: true,
        });

        const root = new THREE.Object3D();
        root.position.x = x;

        const body = new THREE.Mesh(bodyGeometry, bodyMaterial);
        root.add(body);
        body.position.y = bodyHeight / 2;

        // if units are meters then 0.01 here makes size
        // of the label into centimeters.
        const labelBaseScale = 0.01;
        const label = new THREE.Sprite(labelMaterial);
        root.add(label);
        // label.position.y = head.position.y + headRadius + size * labelBaseScale;
        label.position.y = -size * labelBaseScale;
        // label.position.z = bodyDepth / 2;

        label.scale.x = canvas.width * labelBaseScale;
        label.scale.y = canvas.height * labelBaseScale;

        scene.add(root);
        return root;
    }

    var objects = [];
    const step = 1.6;
    var posStart = -1 * step * Math.round(dataSource.length / 2);

    var maxNumber = 0;
    var valorTotal = 0;


    for (var i = 0; i < dataSource.length; i++) {
        const objeto = dataSource[i];
        valorTotal += objeto.value;
        if (maxNumber < objeto.value) {
            maxNumber = objeto.value
        };
    }

    for (var i = 0; i < dataSource.length; i++) {
        const objeto = dataSource[i];

        makeBar(posStart + (step * i), 150, 32, objeto.label, objeto.color, objeto.value, maxNumber);

        // objects.push((<CreateBar text={objeto.label} positionX={posStart + (0.1*i)} positionY={.05} positionZ={0} valor={objeto.value} maxValor={maxNumber} color={`${objeto.color}_texture`}/>
        // ))
    }


    return (
        <mesh><primitive ref={ref} object={scene} {...props} /></mesh>
    )

}

