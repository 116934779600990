import React, {useState, useEffect} from "react";

import styles from "../styles/Global";
import assets from "../assets";
import Button from "./Button";
import { Input } from "./Input";
import { Auth, Cache } from 'aws-amplify';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
  useNavigate
} from "react-router-dom";


const LoginWrapper = ({ title, description, mockupImg, banner }) => {

  const [state, setState] = useState({});
  

  function handleChange(e) {
    console.log(JSON.stringify(state))
    setState({
        ...state,
        [e.target.name]: e.target.value
    });
}

function handleSubmit(e) {
  e.preventDefault();
  const { signedIn, username, password } = state;

  if (!signedIn) {
      setState({ ...state, isSigningIn: true });
      Auth.signIn({
          username,
          password
      }).then((cognitoUser) => {
          console.log('Signed In!');
          console.log("CognitoUser:");
          console.log(JSON.stringify(cognitoUser));

          Auth.currentSession()
          .then((userSession) => {
              console.log("Got user currentSession:");
              console.log(JSON.stringify(userSession?.idToken?.payload));
              setState({ 
                  ...state,
                  signedIn: true, 
                  isSigningIn: false,
                  tokenId: userSession.idToken.jwtToken,
                  refreshToken: userSession.refreshToken.token
              });
          })
          .catch((err) => {
              setState({ ...state, isSigningIn: false });
              console.log(err)
          });

      }).catch((err) => {
          setState({ ...state, isSigningIn: false });
          console.log(err)
      });
  }
}



function changeAuthStorageConfiguration(e) {
  const shouldRememberUser = e.target.checked;
  if (shouldRememberUser) {
      const localStorageCache = Cache.createInstance({
          keyPrefix: "localStorageAuthCache",
          storage: window.localStorage
      });

      Auth.configure({
          storage: localStorageCache
      });
  } else {
      const sessionStorageCache = Cache.createInstance({
          keyPrefix: "sessionAuthCache",
          storage: window.sessionStorage
      });

      Auth.configure({
          storage: sessionStorageCache
      });
  }
}

function handleLogout() {
  if (state.signedIn) {
      setState({ ...state, isSigningOut: true });
      Auth.signOut()
          .then((data) => {
              setState({ 
                  ...state, 
                  signedIn: false, 
                  isSigningOut: false,
                  tokenId: '',
                  refreshToken: ''
              });
              console.log(data);
          })
          .catch((err) => {
              setState({ ...state, isSigningOut: false });
              console.log(err);
          });
  }
}

useEffect(() => {
  componentDidMount();

  return () => {
  }
}, [])


function componentDidMount() {
  setState({ ...state, isSigningIn: true });
  Auth.currentSession()
      .then((userSession) => {
          console.log("Got user currentSession!");
          console.log(userSession);
          setState({ 
            ...state, 
              signedIn: true, 
              isSigningIn: false,
              tokenId: userSession.idToken.jwtToken,
              refreshToken: userSession.refreshToken.token
          });
      })
      .catch((err) => {
        setState({ ...state, isSigningIn: false });
          console.log(err)
      });
}

  return (
    <>
    {
            (state.signedIn) && 
          <Redirect to="/app" />
 }
    <div
      className={`min-h-screen ${styles.section} 
      ${styles.bgPrimary} 
      ${banner}`}
    >
      <div
        className={`flex items-center flex-row md:flex-col w-11/12 sm:w-full minmd:w-3/4`}
      >
        <div
          className={`flex-1 w-full flex max-w-lg justify-start flex-col ml-10 md:ml-0 md:mb-10 bg-white p-4 px-8 pb-10 rounded-xl shadow fadeLeftMini text-left
        `}
        >

          <img
            src={assets.logoHuve}
            className={`sm:h-24 h-24 w-auto py-8 sm:px-0 self-start`}
            loading="lazy"
          />

          <h1
            className={`
          ${styles.blackText}
          ${"font-bold font-IBMPlex text-2xl sm:text-xl minmd:text-3xl minlg:text-3xl leading-12 minmd:leading-13"}`}
          >
            {title}
          </h1>
          <p
            className={`
          ${styles.blackText}
          ${"my-5 font-IBMPlex font-light text-base minmd:text-base minlg:text-base minmd:leading-16"}`}
          >
            {description}
          </p>

          <form onSubmit={handleSubmit}>
            <div className="form-group mb-3">
              <Input
                label="E-mail"
                predicted="California"
                locked={false}
                active={false}
                type="text"
                name="username" 
                id="usernameSignInInput" 
                onChange={ handleChange }
              />
            </div>
            <div className="form-group mb-3">
              <Input
                label="Senha"
                predicted="California"
                locked={false}
                active={false}
                type="password"
                name="password" 
                id="passwordSignInInput" 
                onChange={ handleChange }
              />
            </div>

            {/* <div class="flex items-center mt-5 mb-3"> */}
            <div class="flex items-center pl-4 rounded border border-gray-200 mb-5">
            <input id="rememberMeSignInInput" onChange={changeAuthStorageConfiguration}  defaultChecked type="checkbox"  name="bordered-checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 focus:ring-2 "/>
            <label for="bordered-checkbox-2" className="py-4 ml-2 w-full text-sm font-regular text-gray-900">Lembrar-se de mim</label>

              {/* <input id="rememberMeSignInInput" onChange={changeAuthStorageConfiguration}  defaultChecked type="checkbox" class="w-4 h-4 text-blue-600 bg-gray-100 rounded border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"/>
              <label for="link-checkbox" class="ml-2 text-sm text-gray-900 dark:text-gray-300">Lembrar-se de mim</label> */}
          </div>

            {/* <div className="form-group form-check">
                <input defaultChecked type="checkbox" className="form-check-input" id="rememberMeSignInInput" onChange={changeAuthStorageConfiguration} />
                <label className="form-check-label" htmlFor="rememberMeSignInInput">Lembrar-se de mim</label>
            </div> */}

            <div className={`flex justify-center flex-wrap mt-3`}>
            <button disabled={state.isSigningIn} type="submit" className="px-4 py-3 text-white bg-primary hover:bg-gray-600 rounded-full shadow w-48 text-center">
              Entrar
            </button>
          </div>

          <div className="inline-flex justify-center items-center w-full">
            <hr className="my-8 w-64 h-px bg-gray-200 border-0" />
            <span className="absolute left-1/2 px-3 font-medium text-gray-900 bg-white -translate-x-1/2">
              ou
            </span>
          </div>

          </form>

          <div className={`flex justify-center flex-wrap`}>

            <button className="flex px-5 py-3 text-white justify-center bg-white text-black hover:bg-gray-200 rounded-full shadow w-auto text-center">
            <img
              src={assets.googleLogo}
              className={`h-5 w-auto self-center mr-2`}
              loading="lazy"
            />
              Entrar com Google
            </button>

            {
            (state.signedIn) && (<div>
                {/* <div><b>Your tokenId:</b></div><div>{state.tokenId}</div>
                <div><b>Your refreshToken:</b></div><div>{state.refreshToken}</div> */}
                <button className="btn-toggle ml-5 flex px-5 py-3 text-white justify-center bg-white text-black hover:bg-gray-200 rounded-full shadow w-auto text-center" onClick={handleLogout}>Sair</button>
            </div>)
        }

          </div>
        </div>

        <div className={`flex-1 ${styles.flexCenter}p-8 sm:px-0`}>
          <img
            src={mockupImg}
            alt="mockup"
            className={`fadeRightMini
          ${styles.sectionImg}`}
          />
        </div>
      </div>
    </div>
    </>
  );
};

export default LoginWrapper;
